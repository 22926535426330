<script>
	export let gpg;
</script>

<article class="a-gpg">
	<h2>GPG Key</h2>
	<span data-nosnippet class="key">
		{gpg}
	</span>

</article>

<style>
	.key {
		font-family: monospace;
		font-weight: 100;
		font-size: 1em;
		white-space: pre-wrap;
	}
</style>