<script>
	export let name;
	export let gpg;
	import Gpg from './Gpg.svelte';
	import Contact from './Contact.svelte';
</script>

<main>
	
	<img src="/img/pineapple.png" alt="pineapple" />
	<h1>{name}</h1>
	<Contact />
	<Gpg gpg={gpg} />
</main>

<style>
	main {
		text-align: center;
		padding: 1em;
		max-width: 360px;
		margin: 0 auto;
	}

	img {
		max-height: 200px;
	}

	h1 {
		color: #007d8c;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>
